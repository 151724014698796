//@ts-check
import React, { useState } from 'react'
import { Route, Routes } from 'react-router'
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'

import Content from '../Content/Content'
import Help from '../Help/Help'

import Dashboard from '../../views/Dashboard/Dashboard'
import Administracion from '../../views/Personal/Administracion/Administracion'
import Usuarios from '../../views/Personal/Usuarios/Usuarios'
import UsuarioCrear from '../../views/Personal/Usuarios/UsuarioCrear'
import UsuarioEditar from '../../views/Personal/Usuarios/UsuarioEditar'

const Layout = () => {
  const [help, setHelp] = useState(false)

  return (
    <>
      <Header onHelpClick={() => setHelp(true)} />
      <SideMenu />
      <Content>
        <Routes>
          <Route path='/personal/administracion' element={<Administracion />} />
          <Route path='/personal/usuarios' element={<Usuarios />} />
          <Route path='/personal/usuarios/crear' element={<UsuarioCrear />} />
          <Route
            path='/personal/usuarios/editar/:id'
            element={<UsuarioEditar />}
          />
          <Route path='/' element={<Dashboard />} />
        </Routes>
      </Content>
      <Help open={help} onClose={() => setHelp(false)} />
    </>
  )
}

export default Layout
