import { getAuth } from './auth'
import config from './config'

const BASE_URL = `${config.api}/api/`
const COMMON_ERROR_MESSAGE = 'Something went wrong ...'

const processResponse = async response => {
  const JSONResponse = await response.json()
  if (response.ok) return JSONResponse.body
  if (JSONResponse.message?.includes('admin is not active')) {
    localStorage.removeItem(config.accessTokenKey)
    window.location.reload()
  }
  throw new Error(JSONResponse.message || COMMON_ERROR_MESSAGE)
}
function makeHeaders(useAuth = false, useAcceptAPP_JSON = false) {
  return useAuth
    ? {
        ...(useAcceptAPP_JSON
          ? { Accept: 'application/json' }
          : { 'Content-Type': 'application/json' }),
        Authorization: `Bearer ${getAuth().token}`
      }
    : {
        ...(useAcceptAPP_JSON
          ? { Accept: 'application/json' }
          : { 'Content-Type': 'application/json' })
      }
}
export function GET({ url = '', useAuth = true }) {
  return fetch(BASE_URL + url, {
    method: 'GET',
    headers: makeHeaders(useAuth)
  }).then(async response => {
    return await processResponse(response)
  })
}

/**
 * @typedef CommonMethodParams
 * @property {string} url
 * @property {obect} data
 * @property {boolean} [useAuth]
 * @property {boolean} [useAcceptAPP_JSON]
 */

/**
 * @typedef {(params: CommonMethodParams) => Promise<any>} CommonMethod
 */

/** @type {CommonMethod} */
function commonMethod(params) {
  const {
    url = '',
    data = {},
    useAuth = true,
    useAcceptAPP_JSON = false
  } = params
  return method =>
    fetch(BASE_URL + url, {
      method,
      headers: makeHeaders(useAuth, useAcceptAPP_JSON),
      body: useAcceptAPP_JSON ? data : JSON.stringify(data)
    }).then(async response => {
      return await processResponse(response)
    })
}

/** @type {CommonMethod} */
export const POST = params => commonMethod(params)('POST')
/** @type {CommonMethod} */
export const PUT = params => commonMethod(params)('PUT')
/** @type {CommonMethod} */
export const DELETE = params => commonMethod(params)('DELETE')
