// //@ts-check
import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { createUser } from '../../../utils/api/users'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import UsuarioTabs from '../../../components/Tabs/UsuarioTabs'
import ResumenErroresSalvarUsuario from '../../../modals/ResumenErroresSalvarUsuario/ResumenErroresSalvarUsuario'

const userListqueryKey = ['usuario', 'list']

const UsuarioCrear = () => {
  const [errors, setErrors] = useState([])
  const queryClient = useQueryClient()
  const notification = useNotification()
  const navigate = useNavigate()

  const { isPending: isCreatingUsuario, mutate: crearUsuario } = useMutation({
    mutationFn: createUser,
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: userListqueryKey })
      notification.success({
        title: 'Usuario creado',
        content: 'El usuario ha sido creado correctamente'
      })
      if (!data.errors.length) return navigate('/personal/usuarios')
      setErrors(data.errors)
    },
    onError: err =>
      notification.error({
        title: 'Error al crear el usuario',
        content: err.message
      })
  })

  const handleSalvarUsuario = usuario => {
    if (isCreatingUsuario) return
    crearUsuario(usuario)
  }

  const handleCloseErrorsModal = () => navigate('/personal/usuarios')

  return (
    <>
      <UsuarioTabs
        onSubmit={handleSalvarUsuario}
        isSaving={isCreatingUsuario}
      />
      {errors.length > 0 && (
        <ResumenErroresSalvarUsuario
          errors={errors}
          onClose={handleCloseErrorsModal}
          open
        />
      )}
    </>
  )
}

export default UsuarioCrear
