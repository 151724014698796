import { InputAdornment, TextField } from '@mui/material'
import css from './TableHeader.module.css'

const noop = () => {}

const TableHeader = ({
  actions,
  title = '',
  showSearch = true,
  search = '',
  onSearchChange = noop
}) => (
  <div className={css.main} data-search={showSearch ? 'active' : 'inactive'}>
    <div className={css.actions}>{actions}</div>
    <h2 className={css.title}>{title}</h2>
    {showSearch && (
      <TextField
        value={search}
        onChange={onSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <i className='material-icons'>search</i>
            </InputAdornment>
          )
        }}
        variant='standard'
      />
    )}
  </div>
)

export default TableHeader
