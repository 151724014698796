import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Chip, Fab, IconButton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { PLATFORM_LABELS } from '../../../components/form/PlatformSelector/PlatformSelector'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { findUsers } from '../../../utils/api/users'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { textFilter } from '../../../utils/table'
import css from './Usuarios.module.css'

const StatusIcon = ({ isOk }) => (
  <i
    className={`${css.icon} material-icons`}
    data-status={isOk ? 'valid' : 'invalid'}
  >
    {isOk ? 'check' : 'close'}
  </i>
)

const queryKey = ['usuario', 'list']

const Usuarios = () => {
  const notification = useNotification()

  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const { isLoading, data: userList = [] } = useQuery({
    queryKey,
    queryFn: () =>
      findUsers()
        .then(datos => datos)
        .catch(err => {
          notification.error({
            title: 'Error al recuperar los usuarios',
            content: err.message
          })
          return []
        })
  })

  const handleExport = () => {
    const content = getCSVContent({
      columns: ['Nombre', 'Email', 'Rol', 'Estado'],
      data: userList,
      transform: a => [
        a.name,
        a.email,
        a.role,
        a.active ? 'Bloqueado' : 'Activo'
      ]
    })
    downloadCSVFile(content, 'usuarios')
  }

  const filterSearch = usuario => {
    return textFilter({
      object: usuario,
      fields: ['name', 'email'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        }
        title='Usuarios'
        search={search}
        onSearchChange={e => setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Nombre', key: 'nombre', align: 'left' },
          { title: 'Email', key: 'email', align: 'left' },
          { title: 'Rol', key: 'role', align: 'left' },
          {
            title: 'Permisos',
            key: 'permissions',
            align: 'center',
            className: css.permissionsHeader,
            sortDisabled: true
          },
          {
            title: 'Acceso',
            key: 'acceso',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={userList.filter(filterSearch).map(usuario => ({
          nombre: (
            <span
              className={css.userName}
              onClick={() => navigate('editar/' + usuario.id)}
            >
              {usuario.name}
            </span>
          ),
          email: usuario.email,
          role: usuario.role,
          permissions: (
            <div className={css.permissions}>
              {usuario.permissions.map(
                ({ platform, granted }, i) =>
                  !!granted && (
                    <Chip
                      key={i}
                      label={PLATFORM_LABELS[platform] || platform}
                      size='small'
                      color='secondary'
                    />
                  )
              )}
            </div>
          ),
          acceso: <StatusIcon isOk={usuario.active} />
        }))}
      />
      <Fab
        className={css.nuevo}
        onClick={() => navigate('crear')}
        size='medium'
        color='primary'
        aria-label='add'
      >
        <i className='material-icons'>add</i>
      </Fab>
    </PageLoading>
  )
}

export default Usuarios
