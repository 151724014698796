//@ts-check
import React, { useState } from 'react'
import { Fab, IconButton } from '@mui/material'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import ModalCrearAdmin from '../../../modals/ModalCrearAdmin/ModalCrearAdmin'
import { createAdmin, findAdmins } from '../../../utils/api/admins'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { textFilter } from '../../../utils/table'
import css from './Administracion.module.css'

const StatusIcon = ({ isOk }) => (
  <i
    className={`${css.icon} material-icons`}
    data-status={isOk ? 'valid' : 'invalid'}
  >
    {isOk ? 'check' : 'close'}
  </i>
)

const queryKey = ['admin', 'list']

const Administracion = () => {
  const notification = useNotification()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState('')
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)

  const { isLoading, data: adminList = [] } = useQuery({
    queryKey,
    queryFn: () =>
      findAdmins()
        .then(datos => datos)
        .catch(err => {
          notification.error({
            title: 'Error al recuperar los admins',
            content: err.message
          })
          return []
        })
  })

  const { isPending: isCreatingAdmin, mutate: crearAdmin } = useMutation({
    mutationFn: createAdmin,
    onSuccess: () => {
      setCreateModalOpen(false)
      notification.success({
        title: 'Admin creado',
        content: 'El admin ha sido creado correctamente'
      })
      queryClient.invalidateQueries({ queryKey })
    },
    onError: err =>
      notification.error({
        title: 'Error al crear el admin',
        content: err.message
      })
  })

  const handleExport = () => {
    const content = getCSVContent({
      columns: ['Nombre', 'Email', 'Estado'],
      data: adminList,
      transform: a => [a.name, a.email, a.active ? 'Bloqueado' : 'Activo']
    })
    downloadCSVFile(content, 'administradores')
  }

  const handleCrearAdmin = data => {
    if (isCreatingAdmin) return
    crearAdmin(data)
  }

  const filterSearch = admin => {
    return textFilter({
      object: admin,
      fields: ['name', 'email'],
      search: search
    })
  }
  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        }
        title='Personal de administración'
        search={search}
        onSearchChange={e => setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          { title: 'Email', key: 'email', align: 'left' },
          {
            title: 'Acceso',
            key: 'acceso',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={adminList.filter(filterSearch).map(admin => ({
          name: admin.name,
          email: admin.email,
          acceso: <StatusIcon isOk={admin.active} />
        }))}
      />
      <Fab
        className={css.nuevo}
        disabled={isCreatingAdmin}
        onClick={() => setCreateModalOpen(true)}
        size='medium'
        color='primary'
        aria-label='add'
      >
        <i className='material-icons'>add</i>
      </Fab>
      {isCreateModalOpen && (
        <ModalCrearAdmin
          isSaving={isCreatingAdmin}
          onSubmit={handleCrearAdmin}
          onClose={() => setCreateModalOpen(false)}
          open
        />
      )}
    </PageLoading>
  )
}

export default Administracion
