export const isEmail = email => {
  const email_text = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
  return email_text.test(email)
}

export const isBlank = text => {
  if (!text) return true
  if (typeof text === 'string' && text.trim().length === 0) return true
  if (typeof text === 'object' && text.length === 0) return true
  if (typeof text === 'object' && Object.keys(text).length === 0) return true
  return false
}
