import React from 'react'
import { createRoot } from 'react-dom/client'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { LocalizationProvider, esES } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { StyledEngineProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NotificationManager } from './context/NotificationManager/NotificationManager'
import config from './utils/config'
import theme from './hoc/theme'
import App from './App'
import './index.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
})

const localeText= esES.components.MuiLocalizationProvider.defaultProps.localeText

const app = (
  <React.StrictMode>
    <>
      <CssBaseline />
      <GoogleOAuthProvider clientId={config.google.clientId}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='es' localeText={localeText}>
                  <NotificationManager>
                    <App />
                  </NotificationManager>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </>
  </React.StrictMode>
)

createRoot(document.getElementById('root')).render(app)
