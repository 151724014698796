import config from './config'

export const getAuth = () =>
  JSON.parse(localStorage.getItem(config.accessTokenKey) || '{}')

export const storeAuth = auth =>
  localStorage.setItem(config.accessTokenKey, JSON.stringify(auth))

export const removeAuth = () => localStorage.removeItem(config.accessTokenKey)

export const isAuthenticated = () => getAuth().token != null
