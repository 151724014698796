//@ts-check
import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './BrainRolSelector.module.css'

const ROLES = { admin: 'admin', teacher: 'teacher' }
const ROLE_IDS = Object.values(ROLES)

const RolSelector = ({
  className,
  name,
  label,
  value,
  disabled = false,
  onChange
}) => {
  return (
    <FormControl className={`${className} ${css.main}`} variant='standard'>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        disabled={disabled}
      >
        {ROLE_IDS.map(rol => (
          <MenuItem key={rol} value={rol}>
            {rol}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RolSelector
