import React, { useRef, useState } from 'react'
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import RolSelector from '../form/RolSelector/RolSelector'
import PlatformSelector, {
  PLATFORMS
} from '../form/PlatformSelector/PlatformSelector'
import AgoraRolSelector from '../form/AgoraRolSelector/AgoraRolSelector'
import CentrosSelector from '../form/CentrosSelector/CentrosSelector'
import BrainRolSelector from '../form/BrainRolSelector/BrainRolSelector'
import { isBlank, isEmail } from '../../utils/string'
import Tabs from './Tabs'
import css from './UsuarioTabs.module.css'

function UsuarioTabs({ data, onSubmit, isSaving }) {
  const isEditMode = !!data?.id
  const [user, setUser] = useState(
    data || {
      name: '',
      email: '',
      role: '',
      active: true,
      description: '',
      permissions: []
    }
  )
  const [newPlatform, setNewPlatform] = useState('')
  const [newGranted, setNewGranted] = useState(false)
  const [newDetails, setNewDetails] = useState({})
  const tableBodyRef = useRef(null)
  const navigate = useNavigate()

  const removeRow = position => {
    setUser(state => {
      const currentPermissions = state.permissions
      return {
        ...state,
        permissions: [
          ...currentPermissions.slice(0, position),
          ...currentPermissions.slice(position + 1)
        ]
      }
    })
  }
  const addRow = () => {
    if (newPlatform) {
      setUser(state => {
        const currentPermissions = state.permissions
        return {
          ...state,
          permissions: [
            ...currentPermissions,
            { platform: newPlatform, granted: newGranted, details: newDetails }
          ]
        }
      })
      setNewPlatform('')
      setNewGranted(false)
      setNewDetails({})
      setTimeout(() => {
        const lastRow = tableBodyRef?.current?.lastChild
        lastRow?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }, 10)
    }
  }

  const handleChangePermissions = (index, e) => {
    const { name, value } = e.target
    const newPermissions = [...user.permissions]
    newPermissions[index][name] = value
    setUser(state => ({
      ...state,
      permissions: newPermissions
    }))
  }

  const isSubmitEnabled =
    !isBlank(user.name) && !isBlank(user.role) && isEmail(user.email)

  const handleSubmit = () => {
    onSubmit(user)
  }

  return (
    <div className={css.card}>
      <CardHeader
        title={
          (isEditMode ? 'Editar usuario' : 'Añadir nuevo usuario') +
          (user.name ? ': ' + user.name : '')
        }
        subheader={
          <div className={css.intro}>
            {isEditMode ? (
              <>
                Puedes editar el usuario del equipo de <strong>mundo</strong>
                estudiante rellenando las siguientes pestañas.
              </>
            ) : (
              <>
                Puedes añadir un nuevo usuario al equipo de{' '}
                <strong>mundo</strong>
                estudiante rellenando las siguientes pestañas
              </>
            )}
          </div>
        }
      />
      <CardContent className={css.cardContent}>
        <Tabs
          tabs={[
            {
              name: 'Identidad',
              content: (
                <div className={css.innerContainer}>
                  <TextField
                    className={css.input}
                    label='Nombre'
                    value={user.name}
                    onChange={e => setUser({ ...user, name: e.target.value })}
                    variant='standard'
                  />
                  <TextField
                    className={css.input}
                    label='Email'
                    type='email'
                    value={user.email}
                    onChange={e => setUser({ ...user, email: e.target.value })}
                    variant='standard'
                  />

                  <RolSelector
                    className={css.input}
                    name='role'
                    label='Rol del usuario'
                    value={user.role}
                    onChange={e => setUser({ ...user, role: e.target.value })}
                  />
                  <TextField
                    className={css.input}
                    label='Description'
                    value={user.description}
                    onChange={e =>
                      setUser({ ...user, description: e.target.value })
                    }
                    variant='standard'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={user.active}
                        onChange={e =>
                          setUser({ ...user, active: e.target.checked })
                        }
                      />
                    }
                    label='Activo'
                  />
                </div>
              )
            },
            {
              name: 'Permisos',
              content: (
                <>
                  <div className={css.permissions}>
                    <TableContainer>
                      <Table size='small'>
                        <TableBody ref={tableBodyRef}>
                          {user.permissions?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <PlatformSelector
                                  className={css.input}
                                  label='Plataforma'
                                  name='platform'
                                  value={row.platform}
                                  onChange={e =>
                                    handleChangePermissions(index, e)
                                  }
                                  disabled
                                />
                              </TableCell>
                              <TableCell>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={row.granted}
                                      onChange={e =>
                                        handleChangePermissions(index, {
                                          target: {
                                            name: 'granted',
                                            value: e.target.checked
                                          }
                                        })
                                      }
                                    />
                                  }
                                  label='Acceso'
                                />
                              </TableCell>
                              <TableCell className={css.componentByPlatform}>
                                <ComponentByPlatform
                                  platform={row.platform}
                                  details={row.details}
                                  onChange={newDetails =>
                                    handleChangePermissions(index, {
                                      target: {
                                        name: 'details',
                                        value: newDetails
                                      }
                                    })
                                  }
                                />
                              </TableCell>
                              {!isEditMode ? (
                                <TableCell align='center'>
                                  <Button
                                    variant='text'
                                    onClick={() => removeRow(index)}
                                  >
                                    <i className='material-icons'>delete</i>
                                  </Button>
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow selected>
                          <TableCell>
                            <PlatformSelector
                              className={css.input}
                              label='Plataforma'
                              name='platform'
                              value={newPlatform}
                              filter={option =>
                                !user.permissions
                                  .map(({ platform }) => platform)
                                  .includes(option)
                              }
                              onChange={e => setNewPlatform(e.target.value)}
                            />
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={newGranted}
                                  onChange={e =>
                                    setNewGranted(e.target.checked)
                                  }
                                />
                              }
                              label='Acceso'
                            />
                          </TableCell>
                          <TableCell className={css.componentByPlatform}>
                            <ComponentByPlatform
                              platform={newPlatform}
                              details={newDetails}
                              onChange={setNewDetails}
                            />
                          </TableCell>
                          <TableCell align='center'>
                            <Button
                              variant='contained'
                              disabled={!newPlatform}
                              onClick={addRow}
                            >
                              <i className='material-icons'>add</i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )
            }
          ]}
        />
      </CardContent>
      <CardActions className={css.cardActions}>
        <Button color='error' onClick={() => navigate('/personal/usuarios')}>
          Cancelar
        </Button>
        <Button
          disabled={!isSubmitEnabled || isSaving}
          color='primary'
          onClick={handleSubmit}
          startIcon={isSaving && <CircularProgress size={20} />}
        >
          {isSaving ? 'Guardando...' : 'Guardar'}
        </Button>
      </CardActions>
    </div>
  )
}

export default UsuarioTabs

function ComponentByPlatform({ platform, details = {}, onChange = x => x }) {
  const commonhandleChange = e =>
    onChange({ ...details, [e.target.name]: e.target.value })

  switch (platform) {
    case PLATFORMS.core:
    case PLATFORMS.crm:
    case PLATFORMS.profesores:
      return <p>Pendiente</p>
    case PLATFORMS.agoraUsuario:
      return (
        <>
          <AgoraRolSelector
            className={css.input}
            label='Agora user rol'
            name='rol'
            value={details.rol || ''}
            onChange={commonhandleChange}
          />
          <CentrosSelector
            label='Centros permitidos'
            name='centros_permitidos'
            value={details.centros_permitidos || []}
            onChange={commonhandleChange}
          />
        </>
      )
    case PLATFORMS.brain:
      return (
        <BrainRolSelector
          className={css.input}
          label='Brain rol'
          name='role'
          value={details.role || ''}
          onChange={commonhandleChange}
        />
      )
    case PLATFORMS.agoraProfesor:
    case PLATFORMS.classfyAdmin:
    case PLATFORMS.classfyTeacher:
      return null

    default:
      return <p>Seleccione una plataforma</p>
  }
}
