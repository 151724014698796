//@ts-check
import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material'
import { isBlank, isEmail } from '../../utils/string'
import css from './ModalCrearAdmin.module.css'

const ModalCrearAdmin = ({ open, onSubmit, isSaving, onClose }) => {
  const [usuario, setUsuario] = useState({
    name: '',
    email: '',
    description: ''
  })

  const isSubmitEnabled = !isBlank(usuario.name) && isEmail(usuario.email)

  const handleSubmit = () => {
    onSubmit(usuario)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Añadir nuevo admin</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes añadir un nuevo usuario al equipo de administración de{' '}
          <strong>identity</strong> rellenando el siguiente formulario.
        </DialogContentText>
        <TextField
          className={css.input}
          label='Nombre'
          value={usuario.name}
          onChange={e =>
            setUsuario({ ...usuario, name: e.target.value.trim() })
          }
          variant='standard'
        />
        <TextField
          className={css.input}
          label='Email'
          type='email'
          value={usuario.email}
          onChange={e =>
            setUsuario({ ...usuario, email: e.target.value.trim() })
          }
          variant='standard'
        />
        <TextField
          className={css.input}
          label='Descripción'
          value={usuario.description}
          onChange={e =>
            setUsuario({ ...usuario, email: e.target.value.trim() })
          }
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={!isSubmitEnabled || isSaving}
          color='primary'
          onClick={handleSubmit}
        >
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCrearAdmin
