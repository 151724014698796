//@ts-check
import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './AgoraRolSelector.module.css'

const ROLES = {
  MARKETING: 'MARKETING',
  COORDINADOR: 'COORDINADOR',
  USER: 'USER',
  ADMIN: 'ADMIN',
  DEVELOPER: 'DEVELOPER'
}
const ROLE_IDS = Object.values(ROLES)

const AgoraRolSelector = ({
  className,
  name,
  label,
  value,
  disabled = false,
  onChange
}) => {
  return (
    <FormControl className={`${css.main} ${className}`} variant='standard'>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        disabled={disabled}
      >
        {ROLE_IDS.map(rol => (
          <MenuItem key={rol} value={rol}>
            {rol}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default AgoraRolSelector
