// //@ts-check
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getUser, updateUser } from '../../../utils/api/users'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import PageLoading from '../../../components/PageLoading/PageLoading'
import UsuarioTabs from '../../../components/Tabs/UsuarioTabs'
import ResumenErroresSalvarUsuario from '../../../modals/ResumenErroresSalvarUsuario/ResumenErroresSalvarUsuario'

const userListqueryKey = ['usuario', 'list']

const UsuarioEditar = () => {
  const [errors, setErrors] = useState([])
  const queryClient = useQueryClient()
  const notification = useNotification()
  const navigate = useNavigate()
  const { id } = useParams()
  const queryKey = ['usuario', id]
  const { isLoading, data: user } = useQuery({
    queryKey,
    queryFn: () =>
      getUser(id)
        .then(datos => datos)
        .catch(err => {
          notification.error({
            title: 'Error al recuperar el usuario ' + id,
            content: err.message
          })
          return []
        })
  })

  const { isPending: isUpdatingUsuario, mutate: editarUsuario } = useMutation({
    mutationFn: user => updateUser(user.id, user),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: userListqueryKey })
      queryClient.invalidateQueries({ queryKey })

      notification.success({
        title: 'Usuario editado',
        content: 'El usuario ha sido editado correctamente'
      })
      if (!data.errors.length) return navigate('/personal/usuarios')
      setErrors(data.errors)
    },
    onError: err =>
      notification.error({
        title: 'Error al editar el usuario',
        content: err.message
      })
  })

  const handleSalvarUsuario = usuario => {
    if (isUpdatingUsuario) return
    editarUsuario(usuario)
  }

  const handleCloseErrorsModal = () => navigate('/personal/usuarios')

  return (
    <PageLoading isLoading={isLoading}>
      <UsuarioTabs
        data={user}
        onSubmit={handleSalvarUsuario}
        isSaving={isUpdatingUsuario}
      />
      {errors.length > 0 && (
        <ResumenErroresSalvarUsuario
          errors={errors}
          onClose={handleCloseErrorsModal}
          open
        />
      )}
    </PageLoading>
  )
}

export default UsuarioEditar
