const timestamp = () => {
  const currentdate = new Date()
  let hours = currentdate.getHours()
  if (hours >= 24) hours -= 24
  if (hours < 0) hours += 12
  hours = hours + ''
  if (hours.length === 1) hours = '0' + hours
  let minutes = currentdate.getMinutes() + ''
  if (minutes.length === 1) minutes = '0' + minutes
  let seconds = currentdate.getSeconds() + ''
  if (seconds.length === 1) seconds = '0' + seconds
  let day = currentdate.getDate() + ''
  if (day.length === 1) day = '0' + day
  let month = currentdate.getMonth() + 1 + ''
  if (month.length === 1) month = '0' + month
  let year = currentdate.getFullYear()
  return `${hours}:${minutes}:${seconds} | ${day}/${month}/${year}`
}

export default timestamp
