import { dateFromString } from './date'

export const dateSort = (date1, date2) => {
  const date_1 = dateFromString(date1)
  const date_2 = dateFromString(date2)
  return date_2 - date_1
}

export const textFilter = ({ object = {}, fields = [], search = '' }) => {
  if (!search) return true
  if (fields.length === 0) return false
  const search_text = search.toLowerCase()
  for (let field of fields) {
    const value = (object[field] || '').toString().toLowerCase()
    if (value.includes(search_text)) return true
  }
  return false
}
