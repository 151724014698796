import css from './LoadingIndicator.module.css'

const LoadingIndicator = ({ number }) => (
  <div className={css.indicator}>
    <div />
    <div />
    <div />
    <div />
    <span className={css.number}>{number}</span>
  </div>
)

export default LoadingIndicator
