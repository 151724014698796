import background from './background.gif'
import css from './Dashboard.module.css'

const Dashboard = () => (
  <div className={css.main} data-view='full-screen'>
    <img className={css.bg} src={background} alt='background' />
  </div>
)

export default Dashboard
