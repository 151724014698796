import { createContext, useState, useContext, useEffect } from 'react'
import { Alert, AlertTitle } from '@mui/material'
import css from './NotificationManager.module.css'

const Context = createContext()

const Notification = ({ type, title, content }) => (
  <div className={css.notification}>
    <Alert className={css.alert} severity={type} variant='filled'>
      <AlertTitle>{title}</AlertTitle>
      {content}
    </Alert>
  </div>
)

export const NotificationManager = ({ children }) => {
  const [notification, setNotification] = useState({})

  const success = ({ title, content, timeout = 4000 }) => {
    setNotification({ type: 'success', title, content, timeout })
  }

  const info = ({ title, content, timeout = 4000 }) => {
    setNotification({ type: 'info', title, content, timeout })
  }

  const warning = ({ title, content, timeout = 4000 }) => {
    setNotification({ type: 'warning', title, content, timeout })
  }

  const error = ({ title, content, timeout = 4000 }) => {
    setNotification({ type: 'error', title, content, timeout })
  }

  useEffect(() => {
    if (notification.timeout && notification.timeout > 0) {
      const timeout = setTimeout(() => {
        setNotification({})
      }, notification.timeout)
      return () => clearTimeout(timeout)
    }
    return () => {}
  }, [notification.timeout])

  return (
    <Context.Provider value={{ success, info, warning, error }}>
      {children}
      {notification.title && notification.content && (
        <Notification
          type={notification.type}
          title={notification.title}
          content={notification.content}
        />
      )}
    </Context.Provider>
  )
}

export const useNotification = () => useContext(Context)
