//@ts-check
import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button
} from '@mui/material'
import css from './CentrosSelector.module.css'

const CENTROS = [
  'ALCALA',
  'ALCOBENDAS',
  'ALCORCON',
  'CLASSFY',
  'CORDOBA',
  'ESQUERDO',
  'GOYA',
  'HERNANI',
  'LEGAZPI',
  'LOPEZ DE HOYOS',
  'MAJADAHONDA',
  'MANTERIA',
  'POZUELO',
  'QUEVEDO'
]

const SELECT_ALL_ID = 'select-all'
const CentrosSelector = ({
  className,
  name,
  label,
  value = [],
  disabled = false,
  onChange
}) => {
  const handleChange = (event, node) => {
    const { value } = event.target
    if (node.props.value === SELECT_ALL_ID)
      return onChange({ target: { name, value: CENTROS } })
    onChange({ target: { name, value } })
  }

  const handleClearAll = () => onChange({ target: { name, value: [] } })

  const selectAllChecked = value.length === CENTROS.length

  return (
    <div className={`${css.main} ${className}`}>
      <FormControl variant='standard' fullWidth>
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          labelId={name}
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          label={label}
          disabled={disabled}
          renderValue={selected => selected.join(', ')}
          multiple
        >
          <MenuItem key={SELECT_ALL_ID} value={SELECT_ALL_ID}>
            <Checkbox checked={selectAllChecked} />
            <ListItemText primary='Todos los centros' />
          </MenuItem>
          {CENTROS.map(option => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={value.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
        {!disabled && (
          <Button onClick={handleClearAll} size='small'>
            Limpiar selección
          </Button>
        )}
      </FormControl>
    </div>
  )
}

export default CentrosSelector
