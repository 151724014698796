import React from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { useNavigate } from 'react-router'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { login } from '../../utils/api/login'
import { storeAuth } from '../../utils/auth'
import logo from './identity-black.png'
import css from './Login.module.css'

const Login = () => {
  const navigate = useNavigate()
  const notification = useNotification()

  const onSuccess = auth => {
    if (!auth || typeof auth !== 'object') throw new Error('No auth data!')

    storeAuth(auth)
    notification.success({
      title: 'Sesión iniciada',
      content: `Hola, ${auth.name}`
    })
    navigate('/')
  }

  const onError = e => {
    notification.error({
      title: 'Error iniciando sesión',
      content: 'Ha habido un error verificando tus datos con Google'
    })
  }

  const handleGoogleLogin = credentials => {
    login(credentials).then(onSuccess).catch(onError)
  }

  return (
    <main className={css.main}>
      <img className={css.logo} alt='Identity' src={logo} />
      <GoogleLogin
        text='Iniciar sesión'
        onSuccess={handleGoogleLogin}
        onError={onError}
      />
    </main>
  )
}

export default Login
