//@ts-check
import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './PlatformSelector.module.css'

export const PLATFORMS = {
  agoraProfesor: 'agoraProfesor',
  agoraUsuario: 'agoraUsuario',
  brain: 'brain',
  classfyAdmin: 'classfyAdmin',
  classfyTeacher: 'classfyTeacher'
}

export const PLATFORM_LABELS = {
  [PLATFORMS.agoraProfesor]: 'Ágora (profesor)',
  [PLATFORMS.agoraUsuario]: 'Ágora (usuario)',
  [PLATFORMS.brain]: 'Brain',
  [PLATFORMS.classfyAdmin]: 'Classfy (admin)',
  [PLATFORMS.classfyTeacher]: 'Classfy (teacher)'
}
export const PLATFORM_IDS = Object.values(PLATFORMS)

function PlatformSelector({
  className,
  name,
  label,
  value,
  disabled = false,
  onChange,
  filter = x => x
}) {
  return (
    <FormControl className={`${className} ${css.main}`} variant='standard'>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        disabled={disabled}
      >
        {PLATFORM_IDS.filter(filter).map(platform => (
          <MenuItem key={platform} value={platform}>
            {PLATFORM_LABELS[platform]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PlatformSelector
