export const getCSVContent = ({ columns, data, transform }) => {
  const body = data
    .map(transform)
    .map(row => row.map(cell => cell.replaceAll(';', ',')))
    .map(row => row.map(cell => cell.replaceAll('\n', ' ')))
    .map(row => row.join(';'))
    .join('\n')
  const head = columns.join(';')
  return head + '\n' + body
}

export const downloadCSVFile = (content, filename) => {
  const blob = new Blob([content])
  const blob_url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = blob_url
  a.download = `${filename}.csv`
  document.body.appendChild(a)
  a.click()
  a.remove()
  URL.revokeObjectURL(blob_url)
}
