//@ts-check
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React from 'react'

function ResumenErroresSalvarUsuario({ errors = [], onClose, open }) {
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='lg'>
      <DialogTitle>Resumen errores</DialogTitle>
      <DialogContent>
        <DialogContentText>
          El usuario se ha podido guardar en <code>identity</code> pero se han
          producido los siguientes errores. Siempre puedes volver a editarlo
        </DialogContentText>
        {errors.map(({ message }, i) => (
          <p key={i}>{message}</p>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResumenErroresSalvarUsuario
