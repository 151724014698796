//@ts-check
import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './RolSelector.module.css'

const ROLES = ['USER', 'ADMIN', 'DEVELOPER', 'COORDINATOR', 'TEACHER']

const RolSelector = ({ className, name, label, value, onChange }) => {
  return (
    <FormControl className={`${className} ${css.main}`} variant='standard'>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {ROLES.map(rol => (
          <MenuItem key={rol} value={rol}>
            {rol}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RolSelector
